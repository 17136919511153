import axiosClient from 'api';
import { IOptions } from 'components/Form/CheckboxesTags';
import { baseURL } from 'config';
import DateFns from 'utils/DateFns';
import { ICreateDistributionExport } from 'views/HK_Trading/Distribution/DetailedPlan/types';
import { IFilterParams } from 'views/HK_Trading/Warehouse/Export/ExportDistribution';

export interface IDataPopUp {
  tenantId?: string;
  expectedIds: IOptions[];
  productNames: IOptions[];
}

export interface ICheckCanExportNumber {
  productName: string;
  unit: string;
  amount: number;
  maxAmount: number;
}

interface IUpdate {
  distributionExportId: string;
  audienceId: string;
  audienceName: string;
  paid: number;
  debt: number;
  userId: string;
}

interface ISetFilter {
  expectedCode: string;
  productName: string;
}

class DistributionExportService {
  setData(distributionPlanId: string) {
    return axiosClient.get(`${baseURL}/DistributionExport/SetData`, {
      params: { distributionPlanId },
    });
  }

  add(payload: ICreateDistributionExport) {
    return axiosClient.post(`${baseURL}/DistributionExport/Add`, payload);
  }

  getTenantNames() {
    return axiosClient.get(`${baseURL}/DistributionExport/TenantNames`);
  }

  getDistributionExportDetail(id: string) {
    return axiosClient.get(
      `${baseURL}/DistributionExport/GetDistributionExportDetail`,
      {
        params: { id },
      }
    );
  }

  exportPDF(id: string) {
    return axiosClient.get(
      `${baseURL}/DistributionExport/ExportPDF?distributionExportId=${id}`
    );
  }

  getDistributionExportView(params: IFilterParams) {
    const {
      pageIndex,
      pageSize,
      searchText,
      startExportDate,
      lastExportDate,
      sortBy,
      sortDirection,
      status,
      tenantName,
      payStatus,
    } = params;
    return axiosClient.get(
      `${baseURL}/DistributionExport/GetDistributionExportView`,
      {
        params: {
          SkipCount: (pageIndex - 1) * pageSize,
          MaxResultCount: pageSize,
          Keyword: searchText,
          Sorting: sortBy,
          Descending: sortDirection === 'desc' ? true : false,

          startDate: startExportDate
            ? (function() {
              const date = new Date(startExportDate);
              const utcDate = new Date(Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                0,  // Hours
                0,  // Minutes
                0,  // Seconds
                0   // Milliseconds
              ));
              return utcDate.toISOString();
            })()
            : '',
          lastDate: lastExportDate
            ? (function() {
              const date = new Date(lastExportDate);
              const utcDate = new Date(Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                23, // Hours
                59, // Minutes
                59, // Seconds
                999 // Milliseconds
              ));
              return utcDate.toISOString();
            })()
            : '',

          status,
          tenantName,
          payStatus,
        },
      }
    );
  }

  setFilter(tenantId: string, payload: ISetFilter[]) {
    return axiosClient.post(
      `${baseURL}/DistributionExport/SetFilter`,
      payload,
      {
        params: { tenantId },
      }
    );
  }

  dataPopUp(payload: IDataPopUp) {
    return axiosClient.post(`${baseURL}/DistributionExport/DataPopUp`, {
      ...payload,
      expectedIds: payload.expectedIds
        .map((item) => item.value)
        .filter((x) => x !== 'all'),
      productNames: payload.productNames
        .map((item) => item.value)
        .filter((x) => x !== 'all'),
    });
  }

  checkCanExportNumber(payload: any[]) {
    return axiosClient.post(
      `${baseURL}/DistributionExport/CheckCanExportNumber`,
      payload
    );
  }

  update(payload: IUpdate) {
    return axiosClient.post(`${baseURL}/DistributionExport/Update`, payload);
  }

  updateSuggestPrice(params: any) {
    return axiosClient.put(
      `${baseURL}/DistributionExport/UpdateDEProducts`,
      params
    );
  }
  updateExportNumber(params: any) {
    return axiosClient.put(
      `${baseURL}/DistributionExport/UpdateDEProducts`,
      params
    );
  }
  getPaymentHistory(params: any) {
    return axiosClient.post(
      `${baseURL}/DistributionExport/GetHistoryPaymentById`,
      params
    );
  }
}

export default new DistributionExportService();
