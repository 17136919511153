import axiosClient from 'api';
import { baseURL } from 'config';
import { FilterParams } from 'types';
import DateFns from 'utils/DateFns';

class ProductService {
  getSearchProductList(searchText: string) {
    return axiosClient.get(`${baseURL}/SearchProductSale/Search`, {
      params: {
        productName: searchText,
      },
    });
  }

  getProductList(productName: string) {
    return axiosClient.get(`${baseURL}/SearchProductSale/Search`, {
      params: {
        productName,
      },
    });
  }

  getSaleProduct(productId: number) {
    return axiosClient.get(`${baseURL}/SearchProductSale/SaleProduct`, {
      params: {
        productId,
      },
    });
  }

  getAll({
    pageIndex,
    pageSize,
    searchText,
    startDate,
    lastDate,
    userId
  }: FilterParams) {
    return axiosClient.get(`${baseURL}/BillOfSale/SearchAll`, {
      params: {
        SkipCount: (pageIndex - 1) * pageSize,
        MaxResultCount: pageSize,
        Keyword: searchText,
        startDate: startDate
          ? (function() {
            const date = new Date(startDate);
            const utcDate = new Date(Date.UTC(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              0,  // Hours
              0,  // Minutes
              0,  // Seconds
              0   // Milliseconds
            ));
            return utcDate.toISOString();
          })()
          : '',
        lastDate: lastDate
          ? (function() {
            const date = new Date(lastDate);
            const utcDate = new Date(Date.UTC(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              23, // Hours
              59, // Minutes
              59, // Seconds
              999 // Milliseconds
            ));
            return utcDate.toISOString();
          })()
          : '',
        userId:userId
      },
    });
  }
}

export default new ProductService();
