import axiosClient from 'api';
import { baseURL } from 'config';
import { IEntry } from 'interface';
import { FilterParams } from 'types';
import DateFns from 'utils/DateFns';
class EntryService {
  getAll({
    pageIndex,
    pageSize,
    typeFinance,
    idTypeFinance,
    audienceId,
    startDate,
    lastDate,
    audienceType,
  }: FilterParams) {
    return axiosClient.get(`${baseURL}/Finance/SearchAll`, {
      params: {
        typeFinance: typeFinance,
        idTypeFinance: idTypeFinance,
        audienceId: audienceId,
        audienceType: audienceType,
        startDate: startDate
          ? (function() {
            const date = new Date(startDate);
            const utcDate = new Date(Date.UTC(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              0,  // Hours
              0,  // Minutes
              0,  // Seconds
              0   // Milliseconds
            ));
            return utcDate.toISOString();
          })()
          : '',
        lastDate: lastDate
          ? (function() {
            const date = new Date(lastDate);
            const utcDate = new Date(Date.UTC(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              23, // Hours
              59, // Minutes
              59, // Seconds
              999 // Milliseconds
            ));
            return utcDate.toISOString();
          })()
          : '',
        PageIndex: pageIndex,
        PageSize: pageSize,
        Offset: pageSize,
      },
    });
  }

  getRoles() {
    return axiosClient.get(`${baseURL}/SalePointEmployee/GetRoles`);
  }

  get(id: string) {
    return axiosClient.get(`${baseURL}/Finance/GetDetail`, { params: { id } });
  }

  getEntryType({ pageIndex, pageSize, sortBy, idTypeFinance }: FilterParams) {
    return axiosClient.get(`${baseURL}/TypeFinance/GetAll`, {
      params: {
        TypeFinance: idTypeFinance,
        PageIndex: pageIndex,
        PageSize: pageSize,
        Offset: 10,
      },
    });
  }

  getAudience({ audienceId }: { audienceId: any }) {
    return axiosClient.get(`${baseURL}/RevenueReport/GetAudience`, {
      params: {
        audienceId,
      },
    });
  }

  getEntryAudience({ audienceType }: { audienceType: any }) {
    return axiosClient.get(`${baseURL}/Finance/GetAudience`, {
      params: {
        typeAudence: audienceType,
      },
    });
  }

  create(payload: IEntry) {
    return axiosClient.post(`${baseURL}/Finance/Create`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  update(payload: IEntry) {
    const { files, ...res } = payload;
    return axiosClient.put(`${baseURL}/Finance/Update`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  delete(id: any) {
    return axiosClient.delete(`${baseURL}/Finance/Delete?id=${id}`);
  }

  changeStatus(id: number) {
    return axiosClient.put(`${baseURL}/SalePointEmployee/ChangeStatus`, null, {
      params: { salePointEmployeeId: id },
    });
  }
}

export default new EntryService();
