import { FilterParams } from 'types';

export const defaultFilters: any = {
  pageIndex: 1,
  pageSize: 10,
  sortBy: '',
  sortDirection: 'desc',
  searchText: '',
  startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  lastDate: new Date(),
  supplierName: null,
};

export const defaultFiltersRotation: any = {
  pageIndex: 1,
  pageSize: 10,
  sortBy: '',
  sortDirection: 'desc',
  searchText: '',
  startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  lastDate: new Date(),
  supplierName: null,
};

export const defaultFiltersSaleOfBill: any = {
  pageIndex: 1,
  pageSize: 10,
  sortBy: '',
  sortDirection: 'desc',
  searchText: '',
  startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  lastDate: new Date(),
  userId: null,
};
export const defaultNotiFilters: any = {
  pageIndex: 1,
  pageSize: 20,
  sortBy: '',
  sortDirection: 'desc',
  searchText: '',
  startDate: null,
  lastDate: null,
  supplierName: null,
};
export const defaultFiltersReport: any = {
  tenantId: null,
  From: null,
  To: null,
  pageIndex: 1,
  pageSize: 10,

};

export const defaultFiltersRegisterProduct: any = {
  Keyword: null,
  Descending: true,
  Sorting: null,
  SkipCount: null,
  MaxResultCount: null,

};
